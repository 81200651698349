import { useQuery } from "react-query"
import { CheckStock } from "../../api"
import SearchTableView from "./view"

const SearchTable = ({ symbols, setSymbols }) => {
  const { isLoading, data } = useQuery(
    ["tableData", symbols.sort().toString()],
    () => {
      const date = new Date()
      return CheckStock(symbols, date)
    },
    {
      enabled: symbols.length > 0,
      onSuccess: (data) => {
        console.log(data)
        setSymbols((x) => data.map((x) => x.symbol))
      },
    }
  )
  return <SearchTableView data={data} isLoading={isLoading} />
}

export default SearchTable
