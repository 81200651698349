import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import SymbolTextField from "../symbolTextField"

const FavoriteListView = ({
  symbols,
  addSymbol,
  removeSymbol,
  textFieldSymbol,
  setTextFieldSymbol,
  isLoading,
}) => {
  return (
    <Card
      style={{
        position: "relative",
        pointerEvents: isLoading ? "none" : "all",
      }}
    >
      {isLoading ? (
        <>
          <Box
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        </>
      ) : null}
      <CardHeader title="Favorite Stocks" />
      <CardContent>
        <Box display="flex" alignItems="center">
          <SymbolTextField
            addSymbol={addSymbol}
            textFieldSymbol={textFieldSymbol}
            setTextFieldSymbol={setTextFieldSymbol}
            TextFieldProps={{ disabled: isLoading }}
            IconButtonProps={{ disabled: isLoading }}
          />
          <Box flexGrow={1} />
          <Typography variant="h6">{symbols.length}</Typography>
          <Typography variant="caption" style={{ marginLeft: "4px" }}>
            Symbols
          </Typography>
        </Box>
      </CardContent>
      <CardContent>
        <List>
          {symbols.map((symbol, i) => {
            return (
              <ListItem key={i} divider>
                <ListItemText primary={symbol.symbol} />
                <IconButton
                  size="small"
                  disabled={isLoading}
                  onClick={() => removeSymbol(symbol.id)}
                >
                  <Clear />
                </IconButton>
              </ListItem>
            )
          })}
        </List>
      </CardContent>
    </Card>
  )
}

export default FavoriteListView
