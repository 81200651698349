import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { SaveEmailSettings, GetUserEmailSettings } from "../../api"
import AppContext from "../../AppContext"

const DayCheckBox = ({ day, checked, setChecked }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={setChecked} name={day} />}
      label={day}
    />
  )
}

const SettingsDialog = () => {
  const { dialogs, closeDialog } = useContext(AppContext)
  const saveSettingsMutation = useMutation(SaveEmailSettings)
  const thisDialog = dialogs?.["settingsDialog"] || {}
  const { open } = thisDialog
  const [days, setDays] = useState([
    { name: "Sunday", val: false },
    { name: "Monday", val: false },
    { name: "Tuesday", val: false },
    { name: "Wednesday", val: false },
    { name: "Thursday", val: false },
    { name: "Friday", val: false },
    { name: "Saturday", val: false },
  ])
  const { data: userEmailSettings } = useQuery(
    "userEmailSettings",
    GetUserEmailSettings,
    { enabled: !!open }
  )
  const handleClose = () => closeDialog("settingsDialog")
  const handleSave = () => {
    saveSettingsMutation.mutate(
      { days: days.map((x) => x.val) },
      {
        onSuccess: handleClose,
      }
    )
  }

  useEffect(() => {
    if (userEmailSettings) {
      setDays((d) => d.map((day, i) => ({ ...day, val: userEmailSettings[i] })))
    }
  }, [userEmailSettings])
  return (
    <Dialog open={!!open} fullWidth onEscapeKeyDown={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          Email Settings
          <Box flexGrow={1} />
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex">
          <FormControl component="fieldset">
            <Typography variant="subtitle1" color="primary">
              Choose Days to recieve emails
            </Typography>
            <FormGroup>
              {days.map((day, i) => {
                return (
                  <DayCheckBox
                    key={i}
                    day={day.name}
                    setChecked={(_e, val) =>
                      setDays(
                        days.map((x, ii) => {
                          if (ii === i) return { ...x, val }
                          return x
                        })
                      )
                    }
                    checked={day.val}
                  />
                )
              })}
            </FormGroup>
            <Typography variant="caption">
              *all emails are sent at 7AM EST
            </Typography>
          </FormControl>
          <Divider orientation="vertical" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingsDialog
