import { Auth } from "aws-amplify"
import axios from "axios"

// const API_URL = "http://192.168.1.119:3001/"
const API_URL = window.location.host.includes("localhost")
  ? "http://localhost:3001/"
  : "https://api.dollarcostamplify.com/"

export const CheckStock = async (symbols, date = new Date()) => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + "search",
        { date, symbols },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => resolve(res.data))
      .catch(reject)
  })
}

export const FavoriteSymbols = async () => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "favoriteSymbols", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => resolve(res.data))
      .catch(reject)
  })
}

export const AddFavoriteSymbol = async (symbol) => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + "addFavoriteSymbols",
        { symbol },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => resolve(res.data))
      .catch(reject)
  })
}

export const RemoveFavoriteSymbol = async (id) => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .delete(API_URL + "removefavoriteSymbol/" + id, {
        headers: {
          authorization: token,
        },
      })
      .then((res) => resolve(res.data))
      .catch(reject)
  })
}

export const SaveEmailSettings = async (data) => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + "saveEmailSettings", data, {
        headers: {
          authorization: token,
        },
      })
      .then((res) => resolve(res.ok))
      .catch(reject)
  })
}

export const GetUserEmailSettings = async () => {
  const token = Auth.user.signInUserSession.accessToken.jwtToken
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + "getUserEmailSettings", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => resolve(res.data))
      .catch(reject)
  })
}

// export const GetUserSubscription = async () => {
//   const token = Auth.user.signInUserSession.accessToken.jwtToken
//   return new Promise((resolve, reject) => {
//     axios
//       .get(API_URL + "getUserSubscription", {
//         headers: {
//           authorization: token,
//         },
//       })
//       .then((res) => resolve(res.data))
//       .catch(reject)
//   })
// }

// export const CreateUserSubscription = async (data) => {
//   const token = Auth.user.signInUserSession.accessToken.jwtToken
//   return new Promise((resolve, reject) => {
//     axios
//       .post(API_URL + "createUserSubscription", data, {
//         headers: {
//           authorization: token,
//         },
//       })
//       .then((res) => resolve(res.data))
//       .catch(reject)
//   })
// }

// export const CancelUserSubscription = async () => {
//   const token = Auth.user.signInUserSession.accessToken.jwtToken
//   return new Promise((resolve, reject) => {
//     axios
//       .delete(API_URL + "cancelUserSubscription", {
//         headers: {
//           authorization: token,
//         },
//       })
//       .then((res) => resolve(res.data))
//       .catch(reject)
//   })
// }

// export const CheckValidCoupon = async (data) => {
//   const token = Auth.user.signInUserSession.accessToken.jwtToken
//   return new Promise((resolve, reject) => {
//     axios
//       .post(API_URL + "checkValidCoupon", data, {
//         headers: {
//           authorization: token,
//         },
//       })
//       .then((res) => resolve(res.data))
//       .catch(reject)
//   })
// }
