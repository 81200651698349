import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core"
import {
  AccountCircleRounded,
  AttachMoneyRounded,
  ExitToAppRounded,
  Person,
} from "@material-ui/icons"
import { Auth } from "aws-amplify"
import { useContext, useState } from "react"
import AppContext from "../AppContext"

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { openDialog } = useContext(AppContext)
  const handleClose = () => setAnchorEl(null)

  const logout = async () => {
    handleClose()
    await Auth.signOut()
    window.location.reload()
  }
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">Dollar Cost Amplify</Typography>
        <Box flexGrow={1} />
        <IconButton
          color="inherit"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Person />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              openDialog("settingsDialog")
              handleClose()
            }}
          >
            <AccountCircleRounded style={{ marginRight: "4px" }} />
            Settings
          </MenuItem>
          <MenuItem onClick={logout}>
            <ExitToAppRounded
              style={{ transform: "rotate(180deg)", marginRight: "4px" }}
            />
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header
