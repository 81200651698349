import { useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
const useWidth = () => {
  const theme = useTheme()
  if (theme) {
    const keys = [...theme.breakpoints.keys].reverse()
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return !output && matches ? key : output
      }, null) || "xs"
    )
  } else {
    return "xs"
  }
}

export default useWidth
