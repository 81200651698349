import { Snackbar as MUISnackbar } from "@material-ui/core"
import { useContext, useMemo } from "react"
import AppContext from "../../AppContext"
import useWidth from "../../hooks/useWidth"

const Snackbar = () => {
  const width = useWidth()

  const { snackBar, openSnackBar } = useContext(AppContext)

  const snackBarOrigin = useMemo(() => {
    return width === "sm" || width === "xs"
      ? { vertical: "bottom", horizontal: "left" }
      : { vertical: "bottom", horizontal: "left" }
  }, [width])

  const { message, ...rest } = snackBar

  return (
    <MUISnackbar
      anchorOrigin={snackBarOrigin}
      onClose={() => openSnackBar({ open: false })}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={
        <span style={{ fontSize: "14px" }} id="message-id">
          {message}
        </span>
      }
      {...rest}
    />
  )
}

export default Snackbar
