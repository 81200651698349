import { Box, CardContent, Container, Grid } from "@material-ui/core"
import FavoriteList from "../../components/favoriteList"
import SearchTable from "../../components/searchTable"
import SymbolsChipList from "../../components/symbolsChipList"
import SymbolTextField from "../../components/symbolTextField"

const TablePageView = (props) => {
  return (
    <Container>
      <Grid
        container
        style={{
          marginTop: "40px",
        }}
        spacing={4}
      >
        <Grid item sm={12} md={4}>
          <FavoriteList />
        </Grid>
        <Grid item sm={12} md={8}>
          <Box>
            <SymbolTextField {...props} />
            <SymbolsChipList {...props} />
            <SearchTable {...props} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TablePageView
