import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Amplify from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import App from "./App"
import awsConfig from "./aws-exports"

Amplify.configure(awsConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 5,
    },
  },
})

// const stripePromise = loadStripe(
//   "pk_test_51IR7oiIQhfx48kvRwfnxDiXd6zWZvIBdC1zWGxfG9nSEMM0OkDXGf3W4C8oc8uLkjYSPmtVPaXVRKvtyBPbNcxLs001adoutq5"
// )

const primary = {
  main: "#390096",
}
const secondary = {
  main: "#17A5B1",
}

const spacing = (factor) => factor * 8

const theme = createMuiTheme({
  spacing,
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiTableSortLabel: {
      icon: {
        width: 24,
        height: 24,
      },
    },
    MuiTableCell: {
      body: {
        fontFamily: [
          '"Roboto", "Helvetica", "Arial", sans-serif',
          "!important",
        ],
      },
    },
  },
})

ReactDOM.render(
  <AmplifyAuthenticator>
    <AmplifySignIn slot="sign-in" usernameAlias="email" />
    <AmplifySignUp
      slot="sign-up"
      formFields={[
        {
          type: "username",
          label: "Email Address",
          placeholder: "Enter your email address",
        },
        { type: "password" },
      ]}
    />
    <QueryClientProvider client={queryClient}>
      {/* <Elements stripe={stripePromise}> */}
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
      {/* </Elements> */}
    </QueryClientProvider>
  </AmplifyAuthenticator>,
  document.getElementById("root")
)
