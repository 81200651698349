import { useState } from "react"
import { useQuery } from "react-query"
import { FavoriteSymbols } from "../../api"
import TablePageView from "./view"

const Table = () => {
  const [textFieldSymbol, setTextFieldSymbol] = useState("")
  const { data: favorites } = useQuery(["favoriteSymbols"], FavoriteSymbols)
  const [symbols, setSymbols] = useState([])

  const addSymbol = () => {
    const newSymbols = textFieldSymbol.split(/\n|,|\s/)
    setSymbols([
      ...new Set([
        ...symbols,
        ...newSymbols.map((x) => x.trim().toUpperCase()).filter((x) => x),
      ]),
    ])
    setTextFieldSymbol("")
  }

  const removeSymbol = (symbol) => {
    setSymbols((oldSymbols) => [...oldSymbols.filter((x) => x !== symbol)])
  }

  const clearSymbols = () => setSymbols([])

  const loadFavorites = () => setSymbols(favorites.map((x) => x.symbol))

  return (
    <TablePageView
      textFieldSymbol={textFieldSymbol}
      setTextFieldSymbol={setTextFieldSymbol}
      symbols={symbols}
      addSymbol={addSymbol}
      removeSymbol={removeSymbol}
      clearSymbols={clearSymbols}
      loadFavorites={loadFavorites}
      setSymbols={setSymbols}
    />
  )
}

export default Table
