import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import {
  AddFavoriteSymbol,
  FavoriteSymbols,
  RemoveFavoriteSymbol,
} from "../../api"
import FavoriteListView from "./view"

const FavoriteList = () => {
  const [textFieldSymbol, setTextFieldSymbol] = useState("")
  const [symbols, setSymbols] = useState([])
  const { isLoading: symbolsLoading, data } = useQuery(
    ["favoriteSymbols"],
    FavoriteSymbols
  )
  const newSymbolMutation = useMutation(AddFavoriteSymbol)
  const removeSymbolMutation = useMutation(RemoveFavoriteSymbol)
  const isLoading =
    symbolsLoading ||
    newSymbolMutation.isLoading ||
    removeSymbolMutation.isLoading

  useEffect(() => {
    if (data) setSymbols(data)
  }, [data])

  const addSymbol = () => {
    if (textFieldSymbol) {
      const promiseArr = []
      const newSymbols = textFieldSymbol.split(/\n|,|\s/)
      newSymbols.forEach((x, i) => {
        const symbol = x.trim().toUpperCase()
        if (symbol) {
          promiseArr.push(
            newSymbolMutation
              .mutateAsync(symbol)
              .then((data) => {
                if (i === newSymbols.length - 1) {
                  setSymbols(data)
                }
              })
              .catch((err) => {
                console.log(err)
              })
          )
        }
      })

      return Promise.all(promiseArr)
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          console.log("finally")
          setTextFieldSymbol("")
        })
    }
  }

  const removeSymbol = (id) => {
    removeSymbolMutation.mutate(id, {
      onSuccess: (data) => {
        setSymbols(data)
        setTextFieldSymbol("")
      },
    })
  }

  return (
    <FavoriteListView
      textFieldSymbol={textFieldSymbol}
      setTextFieldSymbol={setTextFieldSymbol}
      symbols={symbols}
      isLoading={isLoading}
      addSymbol={addSymbol}
      removeSymbol={removeSymbol}
    />
  )
}

export default FavoriteList
