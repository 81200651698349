import { Box, Chip, IconButton, Menu, MenuItem } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import { useState } from "react"

const SymbolsChipList = ({
  symbols,
  removeSymbol,
  clearSymbols,
  loadFavorites,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <Box m={1} display="flex" flexWrap="wrap">
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      {symbols.map((symbol) => {
        return (
          <Box m={1} key={symbol}>
            <Chip
              color="primary"
              label={symbol}
              onDelete={() => removeSymbol(symbol)}
            />
          </Box>
        )
      })}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            clearSymbols()
            handleClose()
          }}
        >
          Reset Symbols
        </MenuItem>
        <MenuItem
          onClick={() => {
            loadFavorites()
            handleClose()
          }}
        >
          Load Favorites
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default SymbolsChipList
