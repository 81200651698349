// import { useEffect, useState } from "react"
// import { Auth } from "aws-amplify"
import { Hub } from "@aws-amplify/core"
import { Auth } from "aws-amplify"
import Deferred from "promise-deferred"
import { useEffect, useRef, useState } from "react"
// import { useQuery } from "react-query"
import { BrowserRouter, Route, Switch } from "react-router-dom"
// import { GetUserSubscription } from "./api"
import AppContext from "./AppContext"
import Header from "./components/header"
import Snackbar from "./components/snackbar"
import SettingsDialog from "./dialogs/settingsDialog"
import Table from "./pages/table"

function App() {
  const [dialogs, setDialogsState] = useState({})
  const [authUser, setAuthUser] = useState(null)
  const dialogPromises = useRef({})
  const [snackBar, setSnackbar] = useState({
    open: false,
    message: "There was an error",
    autoHideDuration: 3000,
  })

  const openDialog = (name, data) => {
    setDialogsState({
      ...dialogs,
      [name]: {
        data,
        open: true,
      },
    })
    dialogPromises.current = {
      ...dialogPromises.current,
      [name]: new Deferred(),
    }
    return dialogPromises.current[name].promise
  }

  const closeDialog = (name, confirm = false, data) => {
    // confirm is false by default so dialogs will always reject
    if (confirm) {
      dialogPromises.current[name].resolve(data)
    } else {
      dialogPromises.current[name].reject(data)
    }
    setDialogsState({
      [name]: {
        ...dialogs[name],
        open: false,
      },
    })
    setTimeout(() => {
      setDialogsState({
        [name]: {
          ...dialogs[name],
          data: {},
          open: false,
        },
      })
      delete dialogPromises.current[name]
    }, 300)
  }

  useEffect(() => {
    let updateUser = async () => {
      try {
        let user = await Auth.currentAuthenticatedUser()
        setAuthUser(user)
      } catch {
        setAuthUser(null)
      }
    }
    Hub.listen("auth", updateUser)
    updateUser()
    return () => Hub.remove("auth", updateUser)
  }, [])

  const openSnackBar = ({ open = true, ...rest }) => {
    setSnackbar({
      ...snackBar,
      ...rest,
      open,
    })
  }

  return (
    <AppContext.Provider
      value={{ openDialog, closeDialog, dialogs, openSnackBar, snackBar }}
    >
      <Header />
      {authUser && (
        <>
          <BrowserRouter>
            <Switch>
              <Route path="/">
                <Table />
              </Route>
            </Switch>
          </BrowserRouter>
          <SettingsDialog />
          <Snackbar />
        </>
      )}
    </AppContext.Provider>
  )
}

export default App
