import MaterialTable from "material-table"

const buyStrengthToNumber = (buyStrength) => {
  if (buyStrength === "Very Strong") {
    return 3
  } else if (buyStrength === "Strong") {
    return 2
  } else if (buyStrength === "Average") {
    return 1
  }

  return 0
}

const SearchTableView = ({ data, isLoading }) => {
  return (
    <MaterialTable
      key="main-table"
      title="Symbols"
      columns={[
        {
          title: "Symbol",
          field: "symbol",
        },
        {
          title: "Buy Strength",
          field: "buy_strength",
          customSort: (a, b) =>
            buyStrengthToNumber(a.buy_strength) -
            buyStrengthToNumber(b.buy_strength),
          type: "string",
          defaultSort: "desc",
        },
      ]}
      data={data}
      options={{
        paging: false,
        search: false,
        sorting: true,
        draggable: false,
      }}
      isLoading={isLoading}
    />
  )
}

export default SearchTableView
