import { Box, IconButton, TextField } from "@material-ui/core"
import { Add } from "@material-ui/icons"

const SymbolTextField = ({
  textFieldSymbol,
  setTextFieldSymbol,
  addSymbol,
  TextFieldProps,
  IconButtonProps,
}) => {
  return (
    <Box display="flex">
      <TextField
        label="Symbol"
        value={textFieldSymbol}
        onChange={(e) => setTextFieldSymbol(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            addSymbol()
          }
        }}
        {...TextFieldProps}
      />
      <IconButton onClick={addSymbol} {...IconButtonProps}>
        <Add />
      </IconButton>
    </Box>
  )
}

export default SymbolTextField
